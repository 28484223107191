import { useEffect, useState } from 'react';
import '../styles/ProductDetails.css'
import SpinnerLoader from '../components/SpinnerLoader';
import DetailsHeader from '../components/DetailsHeader';
import ProductDetailsSection from '../components/ProductDetailsSection';
import ProductDetailsSectionInfo from '../components/ProductDetailsSectionInfo';
import ProductActionSection from '../components/ProductActionSection';
import * as language from '../constants/languages';
import { useLoaderData, useParams } from 'react-router-dom';

export default function ProductDetailsPage() {
    let mediaQueryList = window.matchMedia("(max-width: 992px)");
    const { productDetails } = useLoaderData()
    let { product_id } = useParams()
    const [isLoading, setisLoading] = useState(true)
    const [productData, setproductData] = useState([])
    const [imageZoomModal, setimageZoomModal] = useState(false)
    const [imageToZoom, setimageToZoom] = useState([])
    const [SpecificationCheck, setSpecificationCheck] = useState('')
    const [message, setmessage] = useState('')
    const [snackopen, setsnackopen] = useState(false)
    const [qty, setqty] = useState(0)
    const [isMobile, setisMobile] = useState(mediaQueryList.matches)
    const qtyChangeEvent = (data) => {
        if (data.token != 2) {
            getProductDetails();
        }
    }
    useEffect(() => {
        getProductDetails()
        isSpecification();
        window.addEventListener('productQtyChange', qtyChangeEvent)
        if (mediaQueryList.addEventListener) {
            mediaQueryList.addEventListener("change", checkDevice)
        }
        else {
            mediaQueryList.addListener(checkDevice)
        }
        return () => {
            if (mediaQueryList.addEventListener) {
                mediaQueryList.removeEventListener("change", checkDevice)
            }
            else {
                mediaQueryList.removeListener(checkDevice)
            }
            window.removeEventListener('productQtyChange', qtyChangeEvent)
            mediaQueryList.removeEventListener("change", checkDevice)
        }
    }, [])

    //check for Specification tab hide
    async function isSpecification() {
        var spec = localStorage.getItem('show_specification')
        setSpecificationCheck(spec)
    }

    // get product details
    async function getProductDetails() {
        const cart = localStorage.getItem('cartItems');
        const details = productDetails
        let cartItems
        if (details.status === "success") {
            if (cart) {
                cartItems = JSON.parse(cart);
                const elementsIndex = cartItems.customerCartItems.findIndex(element => element.product.productId == product_id);
                if (elementsIndex != -1) {
                    details.data.cartQty = cartItems.customerCartItems[elementsIndex].qty
                    setqty(cartItems.customerCartItems[elementsIndex].qty)
                }
                else {
                    setqty(0)
                }
                setisLoading(false)
                setproductData(details.data)
            } else {
                setisLoading(false)
                setproductData(details.data)
                setqty(0)
            }

        } else {
            setisLoading(true)

        }

    }


    // remove the product
    async function removeItem(id) {
        const cart = localStorage.getItem('cartItems');
        const cartItems = JSON.parse(cart);
        const elementsIndex = cartItems.customerCartItems.findIndex(element => element.product.productId == id);
        cartItems.customerCartItems.splice(elementsIndex, 1);
        localStorage.setItem('cartItems', JSON.stringify(cartItems));
        if (cartItems.customerCartItems.length == 0) {
            localStorage.removeItem('cartItems')
        }
        setmessage(language.removedFromCart)
        setsnackopen(true)

        const event = new CustomEvent('productQtyChange');;
        window.dispatchEvent(event)
    }

    // image pressed for zoom
    async function imagePressed(image) {
        setimageZoomModal(true)
        setimageToZoom(image)

    }

    function checkDevice(mql) {
        setisMobile(mql.matches)
    }
    return (
        <div >
            {/* {
                isMobile &&
                <DetailsHeader />
            } */}

            <div >

                {
                    isLoading == true ?
                        <SpinnerLoader />
                        :

                        <div className='product-details-container'>
                            <div style={{marginTop:15}}>
                                <ProductDetailsSection
                                    productData={productData}
                                    imagePressed={(item) => imagePressed(item)}

                                />
                                {/* <ProductActionSection
                                    cartQny={qty}
                                    cartUnit={productData.unit != '-- None --' ? productData.unit : null}
                                    productData={productData}
                                    removeItem={(id) => removeItem(id)}
                                    noStock={!productData.isStockAvailable}
                                /> */}
                            </div>
                            <ProductDetailsSectionInfo
                                productData={productData}
                                isSpecification={SpecificationCheck}
                            />


                        </div>
                }
            </div>


        </div>


    )
}