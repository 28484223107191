import { useEffect, useState, useContext } from 'react';
import DrawerIcon from '../assets/svg/header/drawericon.svg';
import { AuthContext } from '../pages/RootPage';
import Link from '@mui/material/Link';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { useLocation } from 'react-router-dom';
import RightArrow from '../assets/svg/right.svg'
import { useNavigate } from 'react-router-dom';
import CompanyLogo from "../assets/header_logo.png";
import '../styles/HeaderCommon.css'
function HeaderCommon({ parent, heading }) {
    const navigate = useNavigate()
    const { toggleDrawer } = useContext(AuthContext);
    const location = useLocation()


    return (
        <div className='containerHeaderCommon'>
            <div style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center', alignSelf: 'center' }}>
                {CompanyLogo && <img src={CompanyLogo} height='auto' width={250} />}
            </div>

            {/* <div >
                    <img src={DrawerIcon} style={{ width: 20, height: 15 }} onClick={() => toggleDrawer()} />
                </div>
                <div className='routeWrapper'>
                <p className='route-names' onClick={() => navigate('/')}>Home</p>
                {
                    location.pathname == '/Sharecatalogue' &&
                    <>
                        <img src={RightArrow} />
                        <p className='route-names'  onClick={() => navigate('/Cart')}>Cart</p>
                    </>
                }
                        <img src={RightArrow} />

                <p style={{ fontFamily: 'Poppins-Regular', fontSize: '20px', flexWrap: 'nowrap', margin: 0 }} onClick={() => navigate(location?.pathname)}>{location?.pathname?.slice(1)}</p>

                {
                    location?.state?.heading &&
                    <>
                        <img src={RightArrow} />
                        <p style={{ fontFamily: 'Poppins-Regular', fontSize: '20px', flexWrap: 'nowrap', margin: 0 }} >{location?.state?.heading}</p>

                    </>
                }
                    
                </div>  */}
        </div>
    )

}



export default HeaderCommon